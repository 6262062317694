import { AxiosError, AxiosResponse } from 'axios'
import { Ref, ref } from 'vue'
import { Api } from '../service/__generated-api'

const api = new Api({
  baseURL: process.env.VUE_APP_API_URL
})

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
api.instance.interceptors.request.use(config => {
  config.headers.authorization = window.localStorage.getItem('token')
  return config
})

export default api

type ApiType = typeof api

export function useApi<T>(
  selectAction: (api: ApiType) => Promise<AxiosResponse<T>>
): {
  isLoading: Ref<boolean>
  data: Ref<T | undefined>
  error: Ref<AxiosError | undefined>
  refresh: () => void
} {
  const isLoading = ref(true)
  const data = ref<T>()
  const error = ref<AxiosError>()

  function refresh(): void {
    selectAction(api)
      .then(res => {
        data.value = res.data
      })
      .catch(err => {
        error.value = err
      })
      .finally(() => {
        isLoading.value = false
      })
  }
  refresh()

  return { isLoading, data, error, refresh }
}
