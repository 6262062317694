import { AxiosError, AxiosResponse } from 'axios'
import { Ref, ref } from 'vue'
import { Api } from '../service/__django_generated-api'

const djangoApi = new Api({
  baseURL: process.env.VUE_APP_DJANGO_API_URL
})

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
djangoApi.instance.interceptors.request.use(config => {
  config.headers.authorization = `Token ${window.localStorage.getItem('token')}`
  return config
})

export default djangoApi

type ApiType = typeof djangoApi

export function useDjangoApi<T>(
  selectAction: (api: ApiType) => Promise<AxiosResponse<T>>
): {
  isLoading: Ref<boolean>
  data: Ref<T | undefined>
  error: Ref<AxiosError | undefined>
} {
  const isLoading = ref(true)
  const data = ref<T>()
  const error = ref<AxiosError>()

  selectAction(djangoApi)
    .then(res => {
      data.value = res.data
    })
    .catch(err => {
      error.value = err
    })
    .finally(() => {
      isLoading.value = false
    })

  return { isLoading, data, error }
}
